import styled from "styled-components";

import Button from "../Button";

import { colors } from "../../../config/styles";

export const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  z-index: 10;
  position: fixed;
  top: 63px;
  left: 0;

  width: 100%;

  background: ${colors.MAIN_LIGHTER}; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    ${colors.MAIN_LIGHTER} 0%,
    ${colors.MAIN} 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    ${colors.MAIN_LIGHTER} 0%,
    ${colors.MAIN} 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    ${colors.MAIN_LIGHTER} 0%,
    ${colors.MAIN} 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=' ${colors.MAIN_LIGHTER}', endColorstr='${colors.MAIN}',GradientType=1 ); /* IE6-9 */

  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);

  svg {
    text-align: center;

    path {
      display: block;
      margin: auto;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  margin: 0 1rem;
  padding: 1.125rem 0;

  height: 4.625rem;
  width: 100%;
  max-width: 72.5rem;

  @media screen and (max-width: 768px) {
    height: 7.5rem;
    padding: 1.5rem;
  }
`;

export const Menus = styled.div`
  display: flex;
  align-items: center;

  margin-right: 1rem;

  width: unset;

  @media screen and (max-width: 768px) {
    height: 100%;
  }

  @media screen and (max-width: 550px) {
    width: 100%;
    flex-direction: row-reverse;
  }
`;

export const Products = styled.div`
  display: flex;
  align-items: center;

  margin: 0;

  @media screen and (max-width: 550px) {
    margin: 0 auto;
  }
`;

export const NavbarPlaceholder = styled.div`
  background: ${colors.MAIN_LIGHTER}; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    ${colors.MAIN_LIGHTER} 0%,
    ${colors.MAIN} 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    ${colors.MAIN_LIGHTER} 0%,
    ${colors.MAIN} 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    ${colors.MAIN_LIGHTER} 0%,
    ${colors.MAIN} 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=' ${colors.MAIN_LIGHTER}', endColorstr='${colors.MAIN}',GradientType=1 ); /* IE6-9 */
  position: fixed;
  min-height: 64px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;
`;

export const LogoLink = styled.a`
  display: flex;
`;

export const Logo = styled.img`
  height: 100%;
  width: 6.1875rem;

  margin: auto auto;

  @media screen and (max-width: 768px) {
    height: 100%;
    width: 12rem;
  }
`;

export const ProductsMenu = styled.div`
  position: relative;

  height: 1rem;
  width: 1rem;

  margin-left: 0.25rem;
  margin-right: 1rem;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ProductsMenuButton = styled.button`
  width: 100%;
  height: 100%;

  background: transparent;
  border: none;

  padding: 0;

  cursor: pointer;

  :hover {
          filter: brightness(85%);
        }
`;

export const ProductsMenuDropdown = styled.div`
  display: flex;
  flex-direction: column;

  width: 16rem;
  padding: 1rem;

  position: absolute;

  background: #171c21;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  top: 1.75rem;
  left: -4rem;

  ::before {
    content: "";
    width: 0;
    height: 0;

    position: absolute;
    top: -0.5rem;
    left: 4rem;

    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-bottom: 0.5rem solid #171c21;
  }
`;

export const Product = styled.a`
  display: flex;
  align-items: center;

  img {
    max-height: 2.5rem;
    max-width: 33px;
  }

  div {
    display: flex;
    flex-direction: column;

    margin-left: 0.75rem;

    strong {
      color: #ffffff;
      font-size: 0.75rem;

      text-transform: uppercase;
    }

    span {
      color: #959595;
      font-size: 0.625rem;

      margin-top: 0.25rem;
    }
  }

  + a {
    margin-top: 1rem;
  }
`;

export const HamburguerMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 6rem;

  position: relative;

  padding-right: 3px;

  border: 0px dashed #545d65;
  border-left-width: 1px;
  border-right-width: 1px;

  @media screen and (max-width: 768px) {
    margin-left: 0.5rem;
    width: unset;
    border: none;
  }

  @media screen and (max-width: 550px) {
    border: none;
    width: unset;
    margin-right: 1rem;
  }
`;

export const HamburguerMenuButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  border: none;
  padding: 0;

  cursor: pointer;

  svg {
    width: 1.5rem;
  }

  strong {
    font-size: 0.75rem;
    color: #ffffff;
    text-transform: uppercase;
  }

 :hover {
          filter: brightness(85%);
        }

  @media screen and (max-width: 768px) {
    svg {
      width: 4rem;
    }

    strong {
      display: none;
    }
  }
`;

export const HamburguerMenuDropdown = styled.div`
  display: flex;
  flex-direction: row;

  position: absolute;

  background: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;

  top: 2.25rem;
  left: 1rem;

  ::before {
    content: "";
    width: 0;
    height: 0;

    position: absolute;
    top: -0.5rem;
    left: 0.375rem;

    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-bottom: 0.5rem solid white;
  }

  @media screen and (max-width: 768px) {
    top: 5.5rem;
    transform: translateX(-45%);

    ::before {
      top: -1.5rem;
      left: 8rem;

      border-left: 1.5rem solid transparent;
      border-right: 1.5rem solid transparent;
      border-bottom: 1.5rem solid white;
    }
  }

  @media screen and (max-width: 550px) {
    transform: unset;
    left: -2rem;

    ::before {
      top: -1.5rem;
      left: 2rem;
    }
  }
`;

export const CoreRoutes = styled.div`
  width: 18rem;
  padding: 2rem;

  hr {
    border: none;
    border-top: 1px solid #bebebe;

    margin: 0.75rem 0;
  }

  @media screen and (max-width: 768px) {
    width: 768px;
    padding: 2rem;
    box-shadow: 0 10px 75px rgba(0, 0, 0, 0.6);
  }
`;

export const MainPages = styled.ul`
  display: flex;
  flex-direction: column;

  padding: 0;
  margin: 0;

  a {
    text-decoration: none;

    color: #333333;

    font-size: 1rem;
    font-weight: bold;

    :hover {
      text-decoration: underline;
    }

    + a {
      margin-top: 0.5rem;
    }
  }

  @media screen and (max-width: 768px) {
    a {
      font-size: 2.5rem;
    }
  }
`;

export const SecondaryPages = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0;
  margin: 0;

  a {
    text-decoration: none;
    text-transform: lowercase;

    color: #565656;

    font-size: 0.875rem;

    :hover {
      text-decoration: underline;
    }

    + a {
      margin-top: 0.5rem;
    }
  }

  @media screen and (max-width: 768px) {
    a {
      font-size: 2.5rem;
    }
  }
`;

export const AuthOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin-top: 1rem;
`;

export const AuthButton = styled(Button)`
  width: 100%;
  font-size: 0.75rem;

  padding: 0.25rem;

  + * {
    margin-left: 0.25rem;
  }

  @media screen and (max-width: 768px) {
    padding: 1rem;
    font-size: 2rem;
  }
`;

export const HamburguerMenuSocialMedias = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    margin-top: 2rem;

    a {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        fill: #565656;
        width: 2.5rem;

        transition: fill 0.2s, margin 0.2s;
      }

      + a {
        margin-left: 2rem;
      }

      :hover {
        svg {
          fill: #333333;
        }
      }
    }
  }
`;

export const HighlightedRoutes = styled.div``;

export const Pages = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;

  list-style-type: none;

  padding: 0;
  margin-right: 1rem;

  li {
    + li {
      margin-left: 0.5rem;
    }

    a {
      display: flex;
      justify-content: space-between;
      align-items: center;

      text-decoration: none;

      strong {
        font-size: 0.75rem;
        color: #fff;
        text-transform: uppercase;

        :hover {
          filter: brightness(85%);
        }
      }
    }
  }

  svg {
    path {
      background: red;
    }
  }

  @media screen and (max-width: 1050px) {
    display: none;
  }
`;

export const RightSideMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  margin-left: auto;
  margin-right: 1rem;
`;

export const RightSideMenuSocialMedias = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 1rem;

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: #d5d5d5;

      transition: fill 0.2s, margin 0.2s;
    }

    + a {
      margin-left: 0.3rem;
    }

    :hover {
      svg {
        fill: #ffffff;
        filter: brightness(125%);
      }
    }
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const SearchForm = styled.form`
  height: 100%;
  max-width: 15.75rem;
`;

export const SearchBar = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 0.5rem;

  height: 100%;
  width: 100%;

  background-color: #555d65;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  input {
    height: 100%;
    width: 100%;

    margin-right: 0.5rem;

    background-color: transparent;
    outline: none;
    border: none;

    font-size: 0.75rem;
    color: #ffffff;

    ::placeholder {
      color: #e5e5e5;
      font-style: italic;
    }

    @media screen and (max-width: 768px) {
      font-size: 1.5rem;
    }

    @media screen and (max-width: 550px) {
      display: none;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0;

    border: none;
    background: transparent;

    cursor: pointer;

    svg {
      filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(303deg)
        brightness(103%) contrast(101%);
    }

    @media screen and (max-width: 768px) {
      height: 100%;

      svg {
        height: 100%;
        width: 2.5rem;
      }
    }
  }
`;

export const StrongCFG = styled.strong`
  color: ${colors.NVIDIA_GREEN} !important;
`;
